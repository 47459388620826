/* eslint-disable no-unused-expressions */
<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" />
                列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出
                HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" />
                  HTML
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-category-id="{ data }">
                <SelectBox
                  :transfer="true"
                  v-model="data.CategoryId"
                  v-bind="categoryIdSelectOptions"
                ></SelectBox>
              </template>

              <template #column-tags>
                <SelectBox v-bind="tagSelectOptions" v-model="tags" />
              </template>

              <template #column-description="{ data }">
                <div>
                  <div>
                    <div class="intro-y pr-1">
                      <div class="box">
                        <div
                          class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start  border-b border-gray-200 dark:border-dark-5"
                        >
                          <a
                            data-toggle="tab"
                            data-target="#info"
                            href="javascript:;"
                            class="py-2 sm:mr-8 active"
                            >產品詳細說明</a
                          >
                          <a
                            data-toggle="tab"
                            data-target="#gallery"
                            href="javascript:;"
                            class="py-2 sm:mr-8"
                            >產品圖片</a
                          >

                          <a
                            data-toggle="tab"
                            data-target="#attributes"
                            href="javascript:;"
                            class="py-2 sm:mr-8"
                            >產品屬性設定</a
                          >
                        </div>
                        <div class="tab-content">
                          <div id="info" class="tab-content__pane active">
                            <CKEditor
                              v-model="data.Description.Content"
                              @update:modelValue="
                                value => (data.Description.Content = value)
                              "
                              :editor="classicEditor"
                              :config="editorConfig"
                            />
                          </div>
                          <div id="gallery" class="tab-content__pane ">
                            <div class="grid grid-cols-12 gap-3 mt-2">
                              <div class="intro-y col-span-12 lg:col-span-2">
                                <div class="intro-y box">
                                  <div
                                    class="grid grid-flow-row auto-rows-max content-center"
                                  >
                                    <div class="self-center">產品列表主圖</div>
                                    <div>
                                      <!-- 如要限制上傳尺寸要設定 limitedWidth limitedHeight EX :limitedHeight="500" :limitedWidth="500" -->
                                      <FileUploader
                                        id="news-photo-uri"
                                        style="min-width:150px;max-width:150px;min-height:123px;max-height:123px;"
                                        mode="image"
                                        class="m-2"
                                        :modelValue="data.Photo?.Uri"
                                        :action="uploadAction"
                                        :autoUpload="true"
                                        :itemHeight="500"
                                        :itemWidth="500"
                                        @update:modelValue="
                                          value => {
                                            if (data.Photo)
                                              data.Photo.Uri = value;
                                            else data.Photo = { Uri: value };
                                          }
                                        "
                                        @filter="uploaderFilter"
                                      ></FileUploader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="intro-y col-span-12 lg:col-span-10">
                                <div class="intro-y box">
                                  <div>
                                    <GalleryEditor
                                      :onAdd="galleryAdd"
                                      :onRefresh="galleryRefresh"
                                      :onRemove="galleryRemove"
                                      :onOrderChanged="galleryOrderChanged"
                                      :onSetCover="gallerySetCover"
                                      v-model="data.PictureAlbum.Pictures"
                                    ></GalleryEditor>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="attributes" class="tab-content__pane">
                            <div class="intro-y box">
                              <ProductAttributeGrid
                                ref="attributeGrid1"
                                :modelValue="productAttributeValues"
                                @update:modelValue="
                                  value => {
                                    productAttributeValues = value;
                                  }
                                "
                              ></ProductAttributeGrid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div
              ></template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="button"
              status="primary"
              content="確認"
              @click="submit"
            ></vxe-button>

            <!-- //$refs.form.validate(errMap => {
                //  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                //}) -->
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #color="{ row }">
            <div class="flex">
              <div
                class="border rounded w-7 h-7"
                :style="{ backgroundColor: row.HexValue }"
              ></div>
              &nbsp;
              {{ row.HexValue }}
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  onMounted,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import GalleryEditor from "@/cloudfun/components/GalleryEditor.vue";
import ProductAttributeGrid from "@/cloudfun/components/ProductAttributeGrid.vue";
import { nextTick } from "vue";
export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
    GalleryEditor,
    ProductAttributeGrid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const country = ref([]);
    const unit = ref([]);
    const uploader = ref({});
    const form = ref();
    const galleryUploader = ref({});
    const tags = ref([]);
    const productAttributeValues = ref([]);
    const attributeGrid = ref<any>();
    const attributeGrid1 = ref<any>();
    const formData = ref({
      Id: 0,
      Number: "",
      Name: "",
      Country: { Id: 0, Name: "" },
      Unit: { Id: 0, Name: "" }
    });
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions = {
      stripe: false,
      title: "類別",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "HexValue",
        //   title: "顯示色碼",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   slots: { default: "color" }
        // },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? (params: any) => {
              return model.dispatch("product/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("product/query") : undefined,
        save: model
          ? (params: any) => model.dispatch("product/save", params)
          : undefined
      },
      modalConfig: {
        width: "100%",
        height: "100%",
        showFooter: true,
        showHeader: true,
        resize: true
      }
    };

    const formOptions = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        // {
        //   field: "Id",
        //   span: 24,
        //   slots: { default: "form-body" }
        // }
        {
          field: "Number",
          title: "產品編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Name",
          title: "產品名稱",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "CategoryId",
          title: "產品類別",
          span: 12,
          slots: { default: "column-category-id" }
        },
        {
          field: "ReleaseDate",
          title: "上市日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Country.Id",
          title: "產地",
          span: 12,
          itemRender: {
            name: "$select",
            options: country
            // props: { placeholder: "請輸入文字", clearable: true }
            // attrs: { type: "text" }
          }
        },
        {
          field: "Unit.Id",
          title: "單位",
          span: 12,
          itemRender: {
            name: "$select",
            options: unit
            // props: { placeholder: "請輸入文字", clearable: true }
            // attrs: { type: "text" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Tags",
          title: "產品標籤",
          span: 24,
          slots: { default: "column-tags" }
        },

        {
          field: "Description",
          title: "",
          span: 24,
          slots: { default: "column-description" }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          params.condition.and("Type", Operator.Equal, 1);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const tagSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      multiselect: true,
      placeholder: "新增標籤",
      dropdownWidth: 300,
      textField: "Name",
      valueField: "Id",
      transfer: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("tag/find", value), // eslint-disable-line
        query: params => model!.dispatch("tag/query", params), // eslint-disable-line
        insert: row => model!.dispatch("tag/insert", row) // eslint-disable-line
      }
    };

    const albumFormOptions = {
      titleWidth: 100,
      titleAlign: "right",
      modalWidth: 400,
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "LinkUrl",
          title: "圖片連結",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入圖片連結網址", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
        LinkUrl: [{ type: "string", max: 2000 }]
      }
    };

    onMounted(() => {
      model?.dispatch("country/options").then(
        payload => {
          country.value = payload;
        },
        failure => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );

      model?.dispatch("unit/options").then(
        payload => {
          unit.value = payload;
        },
        failure => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList"
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList"
        },
        view: {
          key: "class"
        }
      });
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const sliderWidth = ref("100%");

    const removeIdProperties = (obj: any) => {
      if (obj !== null && typeof obj === "object") {
        delete obj["$id"];
        Object.values(obj).forEach(value => {
          removeIdProperties(value);
        });
      } else if (Array.isArray(obj)) {
        obj.forEach(item => {
          removeIdProperties(item);
        });
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      unit,
      formData,
      country,
      categoryIdSelectOptions,
      classicEditor,
      editorConfig,
      albumFormOptions,
      tagSelectOptions,
      sliderWidth,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      tempUrl: `${process.env.VUE_APP_BACKEND_URL}/files/temp`,
      galleryUploader,
      tags,
      productAttributeValues,
      removeIdProperties,
      attributeGrid,
      attributeGrid1,
      form
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(current: any, original: any, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    initData(row: any) {
      row.Id = 0;
      row.Published = false;
      row.Ordinal = 0;
      row.Number = "";
      row.Name = "";
      row.Number = "";
      row.Name = "";
      row.Ordinal = 0;
      row.Published = false;
      row.CategoryId = null;
      row.Country = { Id: 0, Name: "" };
      row.Unit = { Id: 0, Name: "" };
      row.Description = {
        Id: 0,
        Content: ""
      };
      row.Photo = {};
      row.PictureAlbum = {
        Id: 0,
        Pictures: []
      };
      row.Tags = [];
      row.ProductAttributeValues = [];
      this.productAttributeValues = [];
      this.tags = [];
    },
    // onSliderEdit(image: any, callback: any) {
    //   callback();
    // },
    // async onSliderRemove(image: any, callback: any) {
    //   await this.$model.dispatch("picture/delete", image.Id);
    //   await this.reloadSlider();
    //   callback();
    // },
    // async onSliderSave(params: any, callback: any) {
    //   if (params.insertRows) {
    //     await this.$model
    //       .dispatch("picture/insert", params.insertRows[0])
    //       .then(payload => {
    //         this.grid.editingRow.PictureAlbum.Pictures.push(payload);
    //       });

    //     if (!this.grid.editingRow.PictureAlbum.Id) {
    //       await this.$model
    //         .dispatch("pictureAlbum/insert", this.grid.editingRow.PictureAlbum)
    //         .then(
    //           payload => {
    //             this.grid.editingRow.PictureAlbum.Id = payload.Id;
    //             this.reloadSlider();
    //           },
    //           reason => {
    //             CloudFun.send("error", {
    //               subject: "重載失敗",
    //               content: reason
    //             });
    //           }
    //         );
    //     } else {
    //       await this.$model
    //         .dispatch("pictureAlbum/update", this.grid.editingRow.PictureAlbum)
    //         .then(
    //           payload => {
    //             this.reloadSlider();
    //           },
    //           reason => {
    //             CloudFun.send("error", {
    //               subject: "重載失敗",
    //               content: reason
    //             });
    //           }
    //         );
    //     }
    //   }

    //   callback();
    // },
    // async reloadSlider() {
    //   await this.$model
    //     .dispatch("pictureAlbum/find", this.grid.editingRow.PictureGallery.Id)
    //     .then(
    //       payload => {
    //         console.log("pictureAlbum/find", payload);
    //         (this.$refs.sliderEditor as any).reload(payload.Pictures);
    //       },
    //       reason => {
    //         CloudFun.send("error", {
    //           subject: "重載失敗",
    //           content: reason
    //         });
    //       }
    //     );
    // },
    galleryAdd(pictures: any) {
      if (pictures) {
        if (
          this.grid.editingRow &&
          this.grid.editingRow.PictureAlbum.Pictures
        ) {
          pictures.forEach((el: any) => {
            this.grid.editingRow.PictureAlbum.Pictures.push({
              Id: 0,
              Uri: `${this.tempUrl}/${el}`
            });
          });
        }

        // this.$model.dispatch("catalogue/upload", params).then(
        //   () => {
        //     this.loadGallery(this.id);
        //     this.pictures = [];
        //   },
        //   reason => {
        //     CloudFun.send("error", {
        //       subject: "上傳失敗",
        //       content: reason
        //     });
        //   }
        // );
      }
    },
    galleryRemove(picture: any) {
      if (picture.Id == 0) {
        this.grid.editingRow.PictureAlbum.Pictures = this.grid.editingRow.PictureAlbum.Pictures.filter(
          (el: any) => el.Uri != picture.Uri
        );
      } else {
        this.$model.dispatch("picture/delete", picture.Id).then(
          paylod => {
            console.log("🚀 ~ galleryRemove ~ paylod:", paylod);

            this.grid.editingRow.PictureAlbum.Pictures = this.grid.editingRow.PictureAlbum.Pictures.filter(
              (el: any) => el.Id != picture.Id
            );
          },
          reason => {
            CloudFun.send("error", {
              subject: "刪除失敗",
              content: reason
            });
          }
        );
      }
    },
    galleryRefresh() {
      console.log("galleryRefresh");
    },
    galleryOrderChanged(pictures: any) {
      console.log("galleryOrderChanged", pictures);
      console.log(
        "this.grid.editingRow.PictureAlbum.Pictures",
        this.grid.editingRow.PictureAlbum.Pictures
      );
    },
    gallerySetCover(picture: any) {
      Object.assign(this.grid.editingRow.Photo, picture);
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("pictureAlbum/find", row.PictureAlbum.Id)
        : undefined;
      if (entity) Object.assign(row.PictureAlbum, entity);
      if (row.PictureAlbum.Pictures)
        row.PictureAlbum.Pictures = row.PictureAlbum.Pictures.sort(
          (a: any, b: any) => a.Ordinal - b.Ordinal
        );

      console.log("🚀 ~ onGridEdit ~ entity:", row.PictureAlbum);
      const attr = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;

      if (attr) {
        const attributes = await this.$model.dispatch(
          "product/getAttributes",
          attr.Id
        );

        setTimeout(() => {
          if (this.attributeGrid1) {
            this.attributeGrid1.setData(attributes);
            console.log("test", this.productAttributeValues);
          }
        }, 500);
      }

      callback();
    },

    async submit() {
      return new Promise((resolve, reject) => {
        const data = this.grid.editingRow;

        console.log(" this.grid.editingRow", data);

        data.Tags = [];

        if (this.tags.length > 0) {
          this.tags.forEach(el => {
            data.Tags.push({ TagId: el });
          });
        }

        data.ProductAttributeValues = [];
        if (this.productAttributeValues.length > 0) {
          this.productAttributeValues.forEach((el: any) => {
            data.ProductAttributeValues.push({
              AttributeValue: {
                AttributeId: el.Id,
                Value: Array.isArray(el.Value)
                  ? (el.Value as Array<string>).join()
                  : el.Value
              },
              AttributeValueId: el.Id,
              Value: Array.isArray(el.Value)
                ? (el.Value as Array<string>).join()
                : el.Value
            });
          });
        }

        const dataObj = this.removeIdProperties(data);
        console.log("🚀 ~ submit ~ data:", data);

        if (!data.Id) {
          this.$model.dispatch("product/insert", data).then(
            payload => {
              console.log("payload", payload);
              CloudFun.send("success", {
                subject: "資料儲存",
                content: "產品新增成功"
              });
              console.log("grid", this.grid);
              this.grid.isModalPopup = false;
              this.grid.refresh();
              resolve(payload);
            },
            failure => {
              CloudFun.send("error", {
                subject: "新增失敗",
                content: failure
              });
              reject(failure);
            }
          );
        } else {
          this.$model.dispatch("product/update", data).then(
            payload => {
              console.log("payload", payload);
              console.log("grid", this.grid);
              CloudFun.send("success", {
                subject: "資料儲存",
                content: "產品更新成功"
              });
              this.grid.isModalPopup = false;
              this.grid.refresh();
              resolve(payload);
            },
            failure => {
              CloudFun.send("error", {
                subject: "更新失敗",
                content: failure
              });
              reject(failure);
            }
          );
        }
      });
    }
  }
});
</script>
<style>
.ck .ck .ck-content {
  min-height: 500px !important;
}
</style>
