
import { defineComponent, ref, watch, toRefs } from "vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import Draggable from "vuedraggable";
import CloudFun from "@cloudfun/core";
export default defineComponent({
  components: {
    FileUploader,
    Draggable
  },
  props: {
    itemWidth: [Number, String],
    itemHeight: [Number, String],
    itemTitle: [String],
    onRefresh: Function,
    onAdd: Function,
    onEdit: Function,
    onSave: Function,
    onRemove: Function,
    onSetCover: Function,
    onOrderChanged: Function,
    modelValue: Array
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const isModalPop = ref(false);
    const isUploadPopup = ref(false);
    const uploader = ref<any>({});
    const albumPictures = ref(props.modelValue);
    const drag = ref(false);

    const catalog = ref({
      Picture: {},
      PictureAlbum: {
        Pictures: Array<any>()
      }
    });
    const pictures = ref<any>([]);
    const modalConfig = ref({
      width: "80%",
      height: "80%",
      resize: true,
      showFooter: true
    });

    const uploadConfig = ref({
      width: "400",
      height: "600",
      showFooter: true
    });

    const loadGallery = (id: any) => {
      model?.dispatch("catalogue/find", id).then(
        payload => {
          console.log("payload", payload);
          catalog.value = payload;
          albumPictures.value = payload.PictureAlbum.Pictures.sort(
            (a: any, b: any) => (a.Ordinal > b.Ordinal ? 1 : -1)
          );

          console.log("albumPictures.value", albumPictures.value);
        },
        reason => {
          CloudFun.send("error", {
            subject: "載入型錄圖檔失敗",
            content: reason
          });
        }
      );
    };

    return {
      modalConfig,
      uploadConfig,
      isModalPop,
      isUploadPopup,
      uploader,
      pictures,
      catalog,
      loadGallery,
      albumPictures,
      drag,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    };
  },
  watch: {
    modelValue(current) {
      this.albumPictures = current;
    }
  },
  methods: {
    reload() {
      const action = () => {
        // this.pictures = images;
        // this.$emit("update:modelValue", this.pictures);
      };
      if (this.$props.onRefresh) this.$emit("refresh");
      else action();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    fileUpload(value: any) {
      this.pictures = value;
    },
    uploadSubmit() {
      if (this.$props.onAdd) this.$emit("add", this.pictures);

      // if (this.pictures) {
      //   const params = {
      //     id: this.id,
      //     pictures: this.pictures.map((el: any) => {
      //       return { Uri: el };
      //     })
      //   };

      //   this.$model.dispatch("catalogue/upload", params).then(
      //     () => {
      //       this.loadGallery(this.id);
      //       this.pictures = [];
      //     },
      //     reason => {
      //       CloudFun.send("error", {
      //         subject: "上傳失敗",
      //         content: reason
      //       });
      //     }
      //   );
      // }
    },
    remove(picture: any) {
      // if (pictureId && confirm("確定刪除")) {
      if (this.$props.onRemove) this.$emit("remove", picture);

      // this.$model.dispatch("picture/delete", pictureId).then(
      //   () => {
      //     this.loadGallery(this.id);
      //     CloudFun.send("success", {
      //       subject: "刪除成功"
      //     });
      //   },
      //   reason => {
      //     CloudFun.send("error", {
      //       subject: "上傳失敗",
      //       content: reason
      //     });
      //   }
      // );
      // }
    },
    // confirm() {
    //   let changed = false;
    //   for (var i = 0; i < this.albumPictures.length; i++) {
    //     if (this.albumPictures[i].Ordinal !== i) {
    //       changed = true;
    //       this.albumPictures[i].Ordinal = i;
    //     }
    //   }

    //   if (changed) {
    //     const params = { updateRows: this.albumPictures };
    //     this.$model.dispatch("picture/save", params).then(
    //       payload => {
    //         this.$emit("close");
    //       },
    //       reason => {
    //         CloudFun.send("error", {
    //           subject: "更新失敗",
    //           content: reason
    //         });
    //       }
    //     );
    //   } else {
    //     this.$emit("close");
    //   }
    // },
    setCover(picture: any) {
      if (this.$props.onSetCover) this.$emit("setCover", picture);
      // this.$model
      //   .dispatch("catalogue/setCover", {
      //     id: this.id,
      //     pictureId: pictureId
      //   })
      //   .then(
      //     payload => {
      //       console.log("payload", payload);
      //     },
      //     reason => {
      //       CloudFun.send("error", {
      //         subject: "更新失敗",
      //         content: reason
      //       });
      //     }
      //   );
    },
    orderChanged(el: any) {
      console.log("sortChanged", el);

      if (this.albumPictures) {
        for (let i = 0; i < this.albumPictures?.length; i++) {
          if (this.albumPictures && this.albumPictures[i])
            (this.albumPictures[i] as any).Ordinal = i + 1;
          console.log("iu", i);
        }

        if (this.$props.onOrderChanged)
          this.$emit("orderChanged", this.albumPictures);
      }
    }
  }
});
