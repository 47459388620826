
import CloudFun, {
  Condition,
  defineComponent,
  onMounted,
  Operator,
  reactive,
  ref,
  Sorting,
  SortOrder
} from "@cloudfun/core";

import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { watch } from "vue-demi";
export default defineComponent({
  components: {
    SelectBox
  },
  props: {
    modelValue: Array
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const categoryId = ref(0);
    const attributeGrid = ref<any>();
    const attributes = ref<any>([]);

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          params.condition.and("Type", Operator.Equal, 1);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    return {
      categoryId,
      attributeGrid,
      categorySelectOptions,
      attributes
    };
  },
  watch: {
    modelValue(current: any) {
      console.log("current", current);
      if (current !== this.attributes) {
        console.log("current", current);
        this.attributes = current;
      }
    }
  },
  methods: {
    applyCategory() {
      if (this.categoryId) {
        if (this.attributes && this.attributes.length > 0) {
          if (!confirm("已存在屬性資料，套用將全部清空！是否確認套用？"))
            return;
        }

        const params = {
          condition: new Condition(
            "CategoryId",
            Operator.Equal,
            this.categoryId.toString()
          ),
          sortings: new Sorting("Ordinal", SortOrder.Ascending)
        };

        this.$model.dispatch("attribute/query", params).then(
          payload => {
            this.attributes = payload.map((el: any) => {
              return {
                Id: el.Id,
                Name: el.Name,
                Code: el.Code,
                Ordinal: el.Ordinal,
                Selectable: el.Selectable,
                Value: "",
                Values: el.Values,
                Multiple: el.Multiple
              };
            });

            this.$emit("update:modelValue", this.attributes);
          },
          failure => {
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        );
      }
    },
    removeItem(row: any) {
      if (row && row.Id && confirm("確定刪除") && this.attributes) {
        this.attributes = this.attributes.filter((el: any) => el.Id !== row.Id);
        this.$emit("update:modelValue", this.attributes);
      }
    },
    setData(data: any) {
      for (const item of data) {
        if (item.Multiple || item.Value.indexOf(",") > -1) {
          const v = item.Value.split(",");
          item.Value = v.map((el: any) => parseInt(el));
        } else {
          item.Value = parseInt(item.Value);
        }
      }

      this.attributes = data;

      if (this.attributeGrid) this.attributeGrid.reloadData(data);
      this.$emit("update:modelValue", this.attributes);
    }
  }
});
