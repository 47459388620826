<template>
  <div class="grid grid-cols-12 gap-6 mt-2">
    <div class="col-span-12 lg:col-span-12 xxl:col-span-12">
      <!-- BEGIN: File Manager Filter -->
      <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
        <div class="w-full sm:w-auto relative mr-auto mt-3 sm:mt-0">
          <!-- <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-700 dark:text-gray-300"
            />
            <input
              type="text"
              class="input w-full sm:w-64 box px-10 text-gray-700 dark:text-gray-300"
              placeholder="Search files"
            />
            <div
              class="inbox-filter dropdown absolute inset-y-0 mr-3 right-0 flex items-center"
              data-placement="bottom-start"
            >
              <ChevronDownIcon
                class="dropdown-toggle w-4 h-4 cursor-pointer text-gray-700 dark:text-gray-300"
              />
              <div class="inbox-filter__dropdown-box dropdown-box pt-2">
                <div class="dropdown-box__content box p-5">
                  <div class="grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-6">
                      <div class="text-xs">File Name</div>
                      <input
                        type="text"
                        class="input w-full border mt-2 flex-1"
                        placeholder="Type the file name"
                      />
                    </div>
                    <div class="col-span-6">
                      <div class="text-xs">Shared With</div>
                      <input
                        type="text"
                        class="input w-full border mt-2 flex-1"
                        placeholder="example@gmail.com"
                      />
                    </div>
                    <div class="col-span-6">
                      <div class="text-xs">Created At</div>
                      <input
                        type="text"
                        class="input w-full border mt-2 flex-1"
                        placeholder="Important Meeting"
                      />
                    </div>
                    <div class="col-span-6">
                      <div class="text-xs">Size</div>
                      <select class="input w-full border mt-2 flex-1">
                        <option>10</option>
                        <option>25</option>
                        <option>35</option>
                        <option>50</option>
                      </select>
                    </div>
                    <div class="col-span-12 flex items-center mt-3">
                      <button
                        class="button w-32 justify-center block bg-gray-200 dark:bg-dark-1 text-gray-600 dark:text-gray-300 ml-auto"
                      >
                        Create Filter
                      </button>
                      <button
                        class="button w-32 justify-center block bg-theme-1 text-white ml-2"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
        <div class="w-full sm:w-auto flex">
          <button
            type="button"
            class="button text-white bg-theme-1 shadow-md mr-2"
            @click="
              () => {
                isUploadPopup = true;
              }
            "
          >
            上傳圖片
          </button>
          <div id="test" class="dropdown">
            <button
              class="dropdown-toggle button px-2 box text-gray-700 dark:text-gray-300"
            >
              <span class="w-5 h-5 flex items-center justify-center">
                <PlusIcon class="w-4 h-4" />
              </span>
            </button>
            <div class="dropdown-box w-40">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                >
                  <FileIcon class="w-4 h-4 mr-2" /> Share Files
                </a> -->
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2" /> 删除
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: File Manager Filter -->
      <!-- BEGIN: Directory & Files -->

      <Draggable
        :list="albumPictures"
        item-key="Id"
        class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5"
        @update="orderChanged"
      >
        <template #item="{ element,index }">
          <div
            class="intro-y col-span-6 sm:col-span-4 md:col-span-3 xxl:col-span-2"
          >
            <div
              class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
            >
              <div class="absolute left-0 top-0 mt-3 ml-3">
                <input class="input border border-gray-500" type="checkbox" />
              </div>

              <a
                href="javascript:;"
                class="w-3/5 file__icon file__icon--image mx-auto"
              >
                <div class="file__icon--image__preview image-fit">
                  <img :src="element.Uri" />
                </div>
              </a>
              <!-- <a href="" class="w-3/5 file__icon file__icon--file mx-auto">
                <div class="file__icon__file-name">
                  {{ picture.Type }}
                </div>
              </a> -->
              <a
                href="javascript:;"
                class="block font-medium mt-4 text-center truncate"
                >{{ itemTitle }}{{ index + 1 }}</a
              >
              <div class="text-gray-600 text-xs text-center mt-0.5">
                <div v-if="element.Uri === catalog.Picture.Uri">封面</div>
                <div v-if="element.Uri != catalog.Picture.Uri">&nbsp;</div>
              </div>
              <div class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                <a class="dropdown-toggle w-5 h-5 block" href="javascript:;">
                  <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
                </a>
                <div class="dropdown-box w-40">
                  <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                    <a
                      v-if="onSetCover != null"
                      href="javascript:;"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="setCover(element)"
                    >
                      <UsersIcon class="w-4 h-4 mr-2" /> 設為封面
                    </a>
                    <a
                      href="javascript:;"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="remove(element)"
                    >
                      <TrashIcon class="w-4 h-4 mr-2" /> 刪除
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Draggable>
    </div>
  </div>

  <vxe-modal
    v-model="isUploadPopup"
    v-bind="uploadConfig"
    transfer
    @confirm="uploadSubmit"
  >
    <div class="flex justify-center">
      <FileUploader
        id="gallery-uploader"
        ref="galleryUploader"
        style="min-width:200px;max-width:200px;min-height:100px;max-height:100px;"
        mode="grid"
        :droppable="true"
        :modelValue="pictures"
        :action="uploadAction"
        :autoUpload="true"
        :limitedHeight="itemHeight"
        :limitedWidth="itemWidth"
        :previewable="false"
        :defaultImage="`https://fakeimg.pl/900x900/?text=Drag and Drop`"
        @update:modelValue="fileUpload"
        @filter="uploaderFilter"
      ></FileUploader>
    </div>
  </vxe-modal>
</template>
<script lang="ts">
import { defineComponent, ref, watch, toRefs } from "vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import Draggable from "vuedraggable";
import CloudFun from "@cloudfun/core";
export default defineComponent({
  components: {
    FileUploader,
    Draggable
  },
  props: {
    itemWidth: [Number, String],
    itemHeight: [Number, String],
    itemTitle: [String],
    onRefresh: Function,
    onAdd: Function,
    onEdit: Function,
    onSave: Function,
    onRemove: Function,
    onSetCover: Function,
    onOrderChanged: Function,
    modelValue: Array
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const isModalPop = ref(false);
    const isUploadPopup = ref(false);
    const uploader = ref<any>({});
    const albumPictures = ref(props.modelValue);
    const drag = ref(false);

    const catalog = ref({
      Picture: {},
      PictureAlbum: {
        Pictures: Array<any>()
      }
    });
    const pictures = ref<any>([]);
    const modalConfig = ref({
      width: "80%",
      height: "80%",
      resize: true,
      showFooter: true
    });

    const uploadConfig = ref({
      width: "400",
      height: "600",
      showFooter: true
    });

    const loadGallery = (id: any) => {
      model?.dispatch("catalogue/find", id).then(
        payload => {
          console.log("payload", payload);
          catalog.value = payload;
          albumPictures.value = payload.PictureAlbum.Pictures.sort(
            (a: any, b: any) => (a.Ordinal > b.Ordinal ? 1 : -1)
          );

          console.log("albumPictures.value", albumPictures.value);
        },
        reason => {
          CloudFun.send("error", {
            subject: "載入型錄圖檔失敗",
            content: reason
          });
        }
      );
    };

    return {
      modalConfig,
      uploadConfig,
      isModalPop,
      isUploadPopup,
      uploader,
      pictures,
      catalog,
      loadGallery,
      albumPictures,
      drag,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    };
  },
  watch: {
    modelValue(current) {
      this.albumPictures = current;
    }
  },
  methods: {
    reload() {
      const action = () => {
        // this.pictures = images;
        // this.$emit("update:modelValue", this.pictures);
      };
      if (this.$props.onRefresh) this.$emit("refresh");
      else action();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    fileUpload(value: any) {
      this.pictures = value;
    },
    uploadSubmit() {
      if (this.$props.onAdd) this.$emit("add", this.pictures);

      // if (this.pictures) {
      //   const params = {
      //     id: this.id,
      //     pictures: this.pictures.map((el: any) => {
      //       return { Uri: el };
      //     })
      //   };

      //   this.$model.dispatch("catalogue/upload", params).then(
      //     () => {
      //       this.loadGallery(this.id);
      //       this.pictures = [];
      //     },
      //     reason => {
      //       CloudFun.send("error", {
      //         subject: "上傳失敗",
      //         content: reason
      //       });
      //     }
      //   );
      // }
    },
    remove(picture: any) {
      // if (pictureId && confirm("確定刪除")) {
      if (this.$props.onRemove) this.$emit("remove", picture);

      // this.$model.dispatch("picture/delete", pictureId).then(
      //   () => {
      //     this.loadGallery(this.id);
      //     CloudFun.send("success", {
      //       subject: "刪除成功"
      //     });
      //   },
      //   reason => {
      //     CloudFun.send("error", {
      //       subject: "上傳失敗",
      //       content: reason
      //     });
      //   }
      // );
      // }
    },
    // confirm() {
    //   let changed = false;
    //   for (var i = 0; i < this.albumPictures.length; i++) {
    //     if (this.albumPictures[i].Ordinal !== i) {
    //       changed = true;
    //       this.albumPictures[i].Ordinal = i;
    //     }
    //   }

    //   if (changed) {
    //     const params = { updateRows: this.albumPictures };
    //     this.$model.dispatch("picture/save", params).then(
    //       payload => {
    //         this.$emit("close");
    //       },
    //       reason => {
    //         CloudFun.send("error", {
    //           subject: "更新失敗",
    //           content: reason
    //         });
    //       }
    //     );
    //   } else {
    //     this.$emit("close");
    //   }
    // },
    setCover(picture: any) {
      if (this.$props.onSetCover) this.$emit("setCover", picture);
      // this.$model
      //   .dispatch("catalogue/setCover", {
      //     id: this.id,
      //     pictureId: pictureId
      //   })
      //   .then(
      //     payload => {
      //       console.log("payload", payload);
      //     },
      //     reason => {
      //       CloudFun.send("error", {
      //         subject: "更新失敗",
      //         content: reason
      //       });
      //     }
      //   );
    },
    orderChanged(el: any) {
      console.log("sortChanged", el);

      if (this.albumPictures) {
        for (let i = 0; i < this.albumPictures?.length; i++) {
          if (this.albumPictures && this.albumPictures[i])
            (this.albumPictures[i] as any).Ordinal = i + 1;
          console.log("iu", i);
        }

        if (this.$props.onOrderChanged)
          this.$emit("orderChanged", this.albumPictures);
      }
    }
  }
});
</script>
